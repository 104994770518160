<div class="container-fluid ">
    <div class="col-sm-12">

        <div class="col-sm-12 m-0 text-center">
            <p class="title-topic">ท่านยังไม่ได้ยืนยันอีเมล
                <br/>กรุณาดำเนินการ
            </p>
        </div>

        <div class="p-2">

        </div>

        <div class="col-sm-12">
            <div class="m-4 custom-border text-center">
                <p class="pt-5 pb-5">ทั้งนี้หากท่านไม่ดำเนินการให้แล้วเสร็จ
                    <br/>ท่านจะไม่สามารถใช้งานแอพพลิเคชั่นได้
                </p>
            </div>
        </div>

        <div class="p-4">

        </div>

        <div class="col-sm-12 text-center pb-3 pt-3">
          <button (click)="confirmInformation()" class="btn btn-primary btn-email pl-5 pr-5">รับทราบ</button>
        </div>
    </div>
</div>
